<template>
    <div>
        <st-modal
            id="st-assign-staff-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="st-assign-staff-modal"
            customClass="form-modal"
            :noCloseOnBackdrop="true"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-4 pb-0">{{ $t("APPLICATION.ASSIGN_MODAL_TITLE") }}</p>
            </template>
            <template #body>
                <assign-staff-form
                    ref="assign-staff-form"
                    @cancelForm="hide"
                    :applicationId="applicationId"
                    :canReturn="canReturn"
                />
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSave"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import AssignStaffForm from './AssignStaffForm.vue';

export default {
    name: 'AssignStaffModal',
    components: { AssignStaffForm },
    props: {
        applicationId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            isReturned: false
        }
    },
    computed: {
        canReturn() {
            return this.isReturned;
        }
    },
    methods: {
        show(isReturned) {
            this.isReturned = !!isReturned;
            
            this.$refs['st-assign-staff-modal'].show();
        },
        hide(isReturned) {
            if (isReturned) {
                this.$emit('refreshActions');
            }

            this.$refs['st-assign-staff-modal'].hide();
        },
        doSave() {
            if (this.isReturned) {
                this.$refs['assign-staff-form'].returnDocument();
            } else {
                this.$refs['assign-staff-form'].submit();
            }
        },
    }
}
</script>
