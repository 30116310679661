<template>
    <div>
        <Loader v-if="loadingUsersDepartment"></Loader>
        <form class="form"
            novalidate="novalidate"
            id="st_modal_assign-staff-form"
            @submit.stop.prevent="onSubmit()"
        >
            <div class="form-group">
                <label>
                    {{fields.staff_users.label}} *
                </label>
                <st-select
                    v-model="model[fields.staff_users.name]"
                    :ref="fields.staff_users.name"
                    :field="fields.staff_users"
                    :formValidation="fv"
                    searchable
                ></st-select>
            </div>

            <div class="form-group" v-if="canReturn">
                <label>
                    {{applicationFields.comments.label}} *
                </label>
                <b-form-textarea
                    v-model="model[applicationFields.comments.name]"
                    :name="applicationFields.comments.name"
                    :ref="applicationFields.comments.name">
                </b-form-textarea>
            </div>
        </form>
    </div>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { FormSchema } from '@/shared/form/form-schema';
import { FunctionaryUserModel } from '@/modules/administrator/models/users-model';
import { ApplicationModel } from '@/modules/applications/models/application-model';

import Message from "@/shared/message/message";
const { fields } = FunctionaryUserModel;
const applicationFields = ApplicationModel.fields;

const formSchema = new FormSchema([
    fields.staff_users,
    applicationFields.comments
])

import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'AssignStaffForm',
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        canReturn: {
            type: Boolean
        }
    },
    data() {
        return {
            fields,
            rules: formSchema.rules(),
            model: {},
            loadingUsersDepartment: false,
            applicationFields,
            fv: null,
            filterStaffOptions: {}
        };
    },
    computed: {
        ...mapGetters({
            accountDetails: "auth/accountDetails",
            record: "applications/form/record",
            applicationTypeFunctions: "applications/form/applicationTypeFunctions",
        }),
    },
    async created() {
        const el = this.fields.staff_users;
        // User staff can be only in one department - for now
        el.fetchParams = {
            organisation_id: this.accountDetails.organisation_id,
            departments: this.accountDetails.departments[0],
            functions: this.applicationTypeFunctions.join(',')
        };

        const initialValue = this.canReturn ? this.record.assigned_staff_user_id : this.accountDetails.user_id;
        this.model = formSchema.initialValues({
            staff_users: initialValue
        });
        
    },
    mounted() {
        this.fv = createFormValidation('st_modal_assign-staff-form', this.rules);
    },
    methods: {
        ...mapActions({
            assign: "applications/form/assignStaff",
            returnDoc: "applications/form/returnDocument",
            addComment: "applications/form/addComments",
        }),
        filterStaffOptionsCallback(elemValue) {
            return this.applicationTypeFunctions.includes(elemValue);
        },
        hide(isReturned) {
            this.$emit('cancelForm', isReturned);
        },
        async submit() {
            const values = await this.validateSelection();
            if (values?.params) {
                this.assign(values.params)
                    .then(() => {
                        this.hide();
                        Message.success(this.$t("APPLICATION.ASSIGN_SUCCESS", { name: values.name }));
                    });
            }
        },
        async returnDocument() {
            const values = await this.validateSelection();
            if (values?.params) {
                await this.returnDoc(values.params);

                //add comments to application
                if (this.model.comments) {
                    await this.addComment({
                        applicationId: this.applicationId,
                        payload: {
                            comment: this.model.comments,
                            visibility: 'private'
                        }
                    });
                }

                this.hide(true);
                Message.success(this.$t( "APPLICATION.ASSIGN_SUCCESS", { name: values.name }));
            }
        },
        async validateSelection() {
            const isValid = await this.fv.validate();
            if (isValid === 'Valid') {
                const selectedUser = this.fields.staff_users.options.find((el) => el.value === this.model.staff_users);
                const payload = {
                    assigned_staff_user_id: this.model.staff_users
                }

                return {
                    params: {
                        applicationId: this.applicationId,
                        payload
                    },
                    name: selectedUser.text
                }
            }

            return {};
        }
    },

};
</script>
