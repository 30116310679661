import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class ResponseTemplatesField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => { 
                return new Promise((resolve, reject) => {
                    const params = {
                        params: 'category eq applications'
                    };
                    
                    ApiService.query('/response-templates', params)
                        .then((response) =>  resolve(response.data.data))
                        .catch((error) => reject(error));
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }

                return {
                    value: record.id,
                    text: record.title,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}