<template>
    <st-modal
        ref="modal"
        hide-header-delimiter
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        hideable
        size="lg"
        :title="modalTitle"
        :hideHeaderClose="isLoading"
        customClass="form-modal"
        id="st-modal-response-notification"
        @modalHidden="modalHidden"
    >
        <template #body>
            <form
                class="form"
                novalidate="novalidate"
                id="st_modal_response_notification_form"
            >
                <div class="form-group">
                    <label class="font-size-h6">
                        {{ fields.comments.label }}
                    </label>
                    <b-form-textarea
                        v-model="model[fields.comments.name]"
                    />
                </div>

                <div class="d-flex justify-content-between mt-10">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                    >
                        <span>{{ $t("GENERAL.BUTTON.SEND") }}</span>
                    </st-button>
                </div>
            </form>
        </template>
    </st-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { FormSchema } from "@/shared/form/form-schema";
import { ApplicationResponseModel } from "@/modules/applications/models/application-response-model";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ApplicationResponseModel;

const formSchema = new FormSchema([
    fields.comments,
]);

export default {
    name: "ApplicationResponseNotificationModal",
    props: {
        applicationId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
        };
    },
    methods: {
        show() {
            this.$refs["modal"].show();
        },
        hide() {
            this.$refs["modal"].hide();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                this.$emit('rejectApplication', this.model);
                this.hide();
            }
        },
        modalHidden() {
            this.$emit("signModalHidden");
        },
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
        }),
        isLoading() {
            return this.loading["applications/confirm-sign"];
        },
        modalTitle() {
            return this.$t("APPLICATION.RESPONSE_MODAL.REJECT_APPLICATION_TITLE");
        }
    },
    async created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.$root.$on("bv::modal::shown", (modalObj) => {
            if (modalObj.componentId === "st-modal-response-notification") {
                this.fv = createFormValidation(
                    "st_modal_response_notification_form",
                    this.rules
                );
            }
        });
    },
};
</script>
